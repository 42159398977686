<template>
  <div class="actors">
    <mobile class="mobile" />
    <desktop class="desktop" />
    <div class="wrapper">
      <div class="slide" v-slidefade2>
        <img loading="lazy" src="@/assets/images/gallery/20.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/names/grace.jpg" alt="" class="gallery-item"/>
        <img loading="lazy" src="@/assets/images/gallery/43.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/21.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/-9.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/-10.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/blank.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/9.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/names/miles.jpg" alt="" class="gallery-item"/>
        <img loading="lazy" src="@/assets/images/gallery/41.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/35.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/42.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/11.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/10.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/blank.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/45.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/names/sophie.jpg" alt="" class="gallery-item"/>
        <img loading="lazy" src="@/assets/images/gallery/4.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/27.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/12.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/blank.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/1.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/names/kat.jpg" alt="" class="gallery-item"/>
        <img loading="lazy" src="@/assets/images/gallery/5.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/26.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/14.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/blank.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/17.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/names/rolfy.jpg" alt="" class="gallery-item"/>
        <img loading="lazy" src="@/assets/images/gallery/2.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/3.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/gallery/16.jpg" alt="" class="gallery-item" />
        <img loading="lazy" src="@/assets/images/annotate/blank.jpg" alt="" class="gallery-item" />
      </div>
    </div>
  </div>
</template>

<script>
import mobile from "../components/mobile.vue";
import desktop from "../components/desktop.vue";
export default {
  components: {
    mobile,
    desktop,
  },
  methods: {},
};
</script>



<style lang="scss" scoped>
.actors {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(black, 0.9), rgba(black, 0.8)),
    url("~@/assets/images/background/collage1.jpg") center no-repeat;
  background-size: cover;
}
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 100vh;
  padding-left: 20%;
  @media (max-width: 1100px) {
    padding-left: 0%;
    padding-top: 110px;
  }
}
.slide {
  img {
    height: 100vh;
    transition: filter 2s;
    color: white;
    text-align: center;
    display: inline-block;
    padding: 1px;
    @media (max-width: 1100px) {
      height: 70vh;
      padding-left: 0%;
    }
  }
}
</style>
